require("@popperjs/core");
require("bootstrap");

(function ($) {

  const SRM = {
    common: {
      init() {
        require("./common");
      },
      finalize() {
        // Run everywhere. Loaded last.

      },
    },
    home: {
      init() {
        // Run on page with body class `home`.
        require("./home");
      },
    },
    single: {
      init() {
        // require('./single');
      },
    },
    page: {
      init() {
        // require('./page');
        // require('./ajax');
      },
    },
    blog: {
      init() {
        //require("./blog");
      },
    },
    contact: {
      init() {
        //require("./contact");
      },
    },
  };

  var util = {
    fire(func, funcname, args) {
      const namespace = SRM;
      funcname = (funcname === undefined) ? "init" : funcname;
      if (func !== "" && namespace[func] && typeof namespace[func][funcname] === "function") {
        namespace[func][funcname](args);
      }
    },
    loadEvents() {
      util.fire("common");

      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), (i, classnm) => {
        util.fire(classnm);
      });

      util.fire("common", "finalize");
    },
  };

  //$(document).ready(util.loadEvents);
  $(util.loadEvents);

}(jQuery));

(function($) {

var $event = $.event,
  $special,
  resizeTimeout;

$special = $event.special.debouncedresize = {
  setup: function() {
    $( this ).on( "resize", $special.handler );
  },
  teardown: function() {
    $( this ).off( "resize", $special.handler );
  },
  handler: function( event, execAsap ) {
    // Save the context
    var context = this,
      args = arguments,
      dispatch = function() {
        // set correct event type
        event.type = "debouncedresize";
        $event.dispatch.apply( context, args );
      };

    if ( resizeTimeout ) {
      clearTimeout( resizeTimeout );
    }

    execAsap ?
      dispatch() :
      resizeTimeout = setTimeout( dispatch, $special.threshold );
  },
  threshold: 50
};

})(jQuery);