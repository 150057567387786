import slick from "slick-carousel";

$(".accordion .collapse").on("shown.bs.collapse", function(e) {
  	$("html,body").animate({
		scrollTop: $(this).parent(".card").offset().top - 50
	});
});

var postSlideWrapper = $(".post-slider");
	
postSlideWrapper.slick({
	autoplaySpeed: 4000,
	lazyLoad: "progressive",
	speed: 600,
	arrows: true,
	infinite: true,
	dots: true,
	cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
});

$(".wp-block-genesis-blocks-gb-profile-box").each( function(e) {
	$(this).find(".gb-profile-text").appendTo($(this).find(".gb-profile-avatar-wrap"));
});

//var boxHeight = $("#page-block-block_60489f08615ab span").height();

//console.log('boxHeight', boxHeight)
/*
$("#page-block-block_60489f08615ab span").on('mouseenter', function(e){
	console.log('in');
	$(this).animate({
		height: "500"
	});
}).on('mouseenter', function(e){
	console.log('out');
	$(this).animate({
		height: boxHeight
	});
});*/

$(".menu-item.menu-item-has-children .togme").on("click", function() {
	//console.log($(this).parent());
	$(this).parent().siblings().find('.dropdown-toggle').removeClass("show");
	$(this).parent().find('.dropdown-toggle').toggleClass("show");
	//$(this).hide();
});

$( "#menuCollapse .wrap-start").prepend("<hr />");
$( "#menuCollapse .wrap-end").append("<hr />");

$(window).on('load', function () {
	$('.page-block').children('.col-12').each( function(e) {

		var elSpan = $(this).find('span');
		var elP = $(this).find('p');

		var elSpanHeight = elSpan[0].clientHeight;

		elSpan.css({
			minHeight: elSpanHeight
		});

		$(this).on('mouseenter', function(e) {
			elSpan.animate({
				height: "100%",
				minHeight: elSpanHeight
			});

			elP.fadeTo(500, 1)
		})
		$(this).on('mouseleave', function(e) {
			elSpan.animate({
				height: elSpanHeight
			});

			elP.fadeTo(500, 0)
		});
	});
});

$(window).on("debouncedresize", function( event ) {

	this.console.log('resized')

	$('#page-block-block_60489f08615ab').children('.col-12').each( function(e) {

		var elSpan = $(this).find('span');

		var elSpanHeight = elSpan[0].clientHeight;

		elSpan.css({
			minHeight: elSpanHeight
		});

	});
});

$(".is-style-anchor").on("click", function(e) {

	e.preventDefault();

	var $this = $('.page');
	var dest = $this.find(".anchored");

	$("html,body").animate({
		scrollTop: $(dest).offset().top
	}, 300);

});

$(".backtotop").on("click", function(e) {

	e.preventDefault();

	$("html,body").animate({
		scrollTop: 0
	}, 300);
	
});